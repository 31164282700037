const APP_STORE_URL = 'https://itunes.apple.com/app/id1459069748';
const ANDROID_PACKAGE_NAME = 'com.c_rayon.friends';
const PC_REDIRECT_URL = APP_STORE_URL;
const CUSTOM_SCHEME = 'friends';

function ready(fn) {
  if (
    document.attachEvent
      ? document.readyState === 'complete'
      : document.readyState !== 'loading'
  ) {
    fn();
  } else {
    document.addEventListener('DOMContentLoaded', fn);
  }
}

function getIdFromParams() {
  const params = location.search.substring(1).split('&');
  return params[0].split('=')[1];
}
function lanchApp(customScheme, customPath) {
  const userAgent = navigator.userAgent.toLowerCase();

  if (userAgent.match(/iphone|ipad|ipod/)) {
    location.href = `${customScheme}://${customPath}`;
    setTimeout(() => {
      location.href = APP_STORE_URL;
    }, 500);
  } else if (userAgent.match(/android/)) {
    document.location.href = `intent://${customPath}#Intent;scheme=${customScheme};package=${ANDROID_PACKAGE_NAME};end`;
  } else {
    location.href = PC_REDIRECT_URL;
  }
}

ready(() => {
  lanchApp(CUSTOM_SCHEME, `link/timeline/${getIdFromParams()}`);
});
